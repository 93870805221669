import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { AccountRequest } from "src/models/account-request.model";
import { environment } from "src/environments/environment";


@Injectable({
    providedIn: 'root'
})
export class AccountRequestService {
    constructor(private httpClient: HttpClient) {}

    API_BASE_URL = environment.apiUrl;

    getOpenRequests(): Observable<AccountRequest[]> {
        let headers = new HttpHeaders().set("Authorization", JSON.stringify(sessionStorage.getItem('TOKEN')));
        return this.httpClient.get<AccountRequest[]>(this.API_BASE_URL + '/api/account-request/open', {headers});
    }

    createAccountRequest(accountRequest: AccountRequest) {
        return this.httpClient.post<AccountRequest>(this.API_BASE_URL + '/api/account-request/create', {params: {accountRequest}});
    }

    updateAccountRequest(accountRequest: AccountRequest) {
        let headers = new HttpHeaders().set("Authorization", JSON.stringify(sessionStorage.getItem('TOKEN')));
        return this.httpClient.put<AccountRequest>(this.API_BASE_URL + '/api/account-request/update', {params: {accountRequest, Authorization: JSON.stringify(sessionStorage.getItem('TOKEN'))}});
    }
    
}