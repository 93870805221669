import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class EmailService {
    constructor(private httpClient: HttpClient) {}

    API_BASE_URL = environment.apiUrl;

    sendRequestConfirmation(email: string): Observable<any> {
        return this.httpClient.post<any>(this.API_BASE_URL + '/api/email/request-confirmed', {params: {email}});
    }

    sendRequestApproval(email: string) {
        let headers = new HttpHeaders().set("Authorization", JSON.stringify(sessionStorage.getItem('TOKEN')));
        return this.httpClient.post<any>(this.API_BASE_URL + '/api/email/request-approved', {params: {email, Authorization: JSON.stringify(sessionStorage.getItem('TOKEN'))}, headers});
    }
}